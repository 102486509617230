import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { apiAuthModel, apiResponse, authModel, authStorage, authUserModel, storageApiKey } from '../models/auth';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    public getClients(): Observable<apiResponse> {
        // Create the headers for the request
        const headers = new HttpHeaders().set('Accept', 'application/json');

        // Make the GET request to the server
        return this.http.get<apiResponse>(`${environment.apiUrl}/api/v1/client/`, { headers });
    }

    public login(username: string, password: string, remember_me: boolean, deviceToken: string, level: string): Observable<apiAuthModel> {
        // Create the body of the request
        const body = new HttpParams()
            .set('grant_type', 'password')
            .append('level', level)
            .append('username', username)
            .append('password', password);

        // Create the headers for the request
        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', this.encodeBasicHeader())
            .append('Content-Type', 'application/x-www-form-urlencoded');

        // Make the POST request to the server
        return this.http.post<apiAuthModel>(`${environment.apiUrl}/api/v1/oauth/token/`, body, { headers })
            .pipe(
                // If the login is successful, store the user details and jwt token in local storage
                map(user => {
                    localStorage.setItem('auth', authStorage.createapiAuth(user, remember_me, deviceToken, username, level));
                    return user;
                })
            );
    }

    public loginWithRefreshToken(refreshToken: string): Observable<authModel> {
        // Get the current user from storage
        const storedAuthJson = localStorage.getItem('auth');
        const oldKey: authModel = storedAuthJson ? JSON.parse(storedAuthJson) : null;

        // Create the body of the request
        const body = new HttpParams()
            .set('grant_type', 'refresh_token')
            .append('refresh_token', refreshToken)
            .append('username', oldKey.username);

        // Create the headers for the request
        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', this.encodeBasicHeader())
            .append('Content-Type', 'application/x-www-form-urlencoded');

        // Make the POST request to the server
        return this.http.post<apiAuthModel>(`${environment.apiUrl}/api/v1/oauth/token/`, body, { headers })
            .pipe(
                // If the login is successful, store the user details and jwt token in local storage
                map(user => {
                    const authModel = authStorage.createapiAuth(user, oldKey.save_login, String(oldKey.device_token), oldKey.username, oldKey.level);
                    localStorage.setItem('auth', authModel);
                    return JSON.parse(authModel);
                })
            );
    }

    public loginWithApiKey(apiKey: string, username: string, deviceToken: string): Observable<authModel> {
        // Get the current user from storage
        const storedAuthJson = localStorage.getItem('auth');
        const oldKey: authModel = storedAuthJson ? JSON.parse(storedAuthJson) : null;

        // Create the body of the request
        const body = new HttpParams()
            .set('grant_type', 'password')
            .append('username', username)
            .append('password', apiKey)
            .append('device_token', deviceToken)
            .append('level', 'key');

        // Create the headers for the request
        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', this.encodeBasicHeader())
            .append('Content-Type', 'application/x-www-form-urlencoded');

        // Make the POST request to the server
        return this.http.post<apiAuthModel>(`${environment.apiUrl}/api/v1/oauth/token/`, body, { headers })
            .pipe(
                // If the login is successful, store the user details and jwt token in local storage
                map(user => {
                    const authModel = authStorage.createapiAuth(user, oldKey.save_login, String(oldKey.device_token), oldKey.username, oldKey.level);
                    localStorage.setItem('auth', authModel);
                    return JSON.parse(authModel);
                })
            );
    }

    public requestApiKey(deviceToken: string): Observable<any> {
        // Create the body of the request
        const body = new HttpParams().set('device_token', deviceToken);

        // Create the headers for the request
        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('Authorization', `Bearer ${this.getAuth().access_token}`);

        // Make the POST request to the server
        return this.http.post<any>(`${environment.apiUrl}/api/v1/authentication/key/`, body, { headers })
            .pipe(
                // If the request is successful, store the API key in local storage
                map(api => {
                    localStorage.setItem('apiKey', authStorage.createapiKey(api.data));
                    return api;
                })
            );
    }

    public getAuthUserInformation(): Observable<authUserModel> {
        // Create the headers for the request
        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('Authorization', `Bearer ${this.getAuth().access_token}`);

        // Make the GET request to the server
        return this.http.get<apiResponse>(`${environment.apiUrl}/api/v1/authentication/`, { headers })
        .pipe(map(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return response.data;
        }));
    }

    public updateUserInformation(companyId: number, employeeId: number): Observable<any> {
        // Create the body of the request
        const body = new HttpParams()
            .set('companyId', String(companyId))
            .append('employeeId', String(employeeId));

        // Create the headers for the request
        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', `Bearer ${this.getAuth().access_token}`);

        // Make the PUT request to the server
        return this.http.put<any>(`${environment.apiUrl}/api/v1/authentication/`, body, { headers })
            .pipe(
                // Extract the data from the response
                map(user => user['data'])
            );
    }

    public forgotPassword(username: string, level: string) {
        const body = new HttpParams()
            .set('username', username)
            .append('level', level);

        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', this.encodeBasicHeader());

        return this.http.put<any>(`${environment.apiUrl}/api/v1/authentication/forgot/`, body, { headers })
            .pipe(map(response => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return response;
            }));
    }

    public updateAccount(password: string, passwordCurrent: string) {
        const body = new HttpParams()
            .set('password', password)
            .append('passwordCurrent', passwordCurrent);

        const headers = new HttpHeaders()
            .set('Accept', 'application/json')
            .append('Authorization', `Bearer ${this.getAuth().access_token}`);

        return this.http.put<any>(`${environment.apiUrl}/account/v1/account/`, body, { headers })
            .pipe(map(response => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                return response;
            }));
    }

    public generateDeviceToken(length: number) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    public checkLoginState(): boolean {
        const storedAuthJson = localStorage.getItem('auth');
        if (storedAuthJson !== null) {
            const auth: authModel = JSON.parse(storedAuthJson);
            const storedApiKeyJson = localStorage.getItem('apiKey');
            const apiKey: storageApiKey = storedApiKeyJson ? JSON.parse(storedApiKeyJson) : null;

            // Check if API key is present and valid
            if (apiKey && apiKey.api_key !== undefined) {
                const apiKeyExpireDate = moment(apiKey.api_key_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
                if (new Date() < apiKeyExpireDate) {
                    return true; // API key is valid
                } else {
                    this.logout(); // API key expired
                    return false;
                }
            } else { // No API key, check refresh token expiry
                const refreshTokenExpireDate = moment(auth.refresh_token_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
                if (new Date() < refreshTokenExpireDate) {
                    return true; // Refresh token is valid
                } else {
                    this.logout(); // Refresh token expired
                    return false;
                }
            }
        } else { // No auth data in storage
            return false;
        }
    }

    public checkApiKeyValidity() {
        // Get the API key from localStorage
        const apiKeyString: string | null = localStorage.getItem('apiKey');

        // Check if the API key exists
        if (apiKeyString !== null) {
            const apiKey: storageApiKey = JSON.parse(apiKeyString);
            // Use moment instead of Date for support on mobile browsers
            let expireDate = moment(apiKey.api_key_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
            const now = new Date();
            if (expireDate > now) {
                return true;
            } else {
                return false;
            }
        } else {
            // No API key set.
            return false;
        }
    }

    public checkRefreshTokenValidity() {
        // Get the authentication data from localStorage
        const authString: string | null = localStorage.getItem('auth');

        // Check if the authentication data exists
        if (authString !== null) {
            const auth: authModel = JSON.parse(authString);
            // Use moment instead of Date for support on mobile browsers
            let expireDate = moment(auth.refresh_token_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
            expireDate.setMinutes(expireDate.getMinutes() - 5); // Subtract 5 minutes from expiration time
            const now = new Date();
            if (expireDate > now) {
                return true;
            } else {
                return false;
            }
        } else {
            // No authentication data set.
            return false;
        }
    }

    public checkAccessTokenValidity() {
        // Get the authentication data from localStorage
        const authString: string | null = localStorage.getItem('auth');

        // Check if the authentication data exists
        if (authString !== null) {
            const auth: authModel = JSON.parse(authString);
            // Use moment instead of Date for support on mobile browsers
            let expireDate = moment(auth.access_token_expires, 'YYYY-MM-DD hh:mm:ss').toDate();
            expireDate.setMinutes(expireDate.getMinutes() - 5); // Subtract 5 minutes from expiration time
            const now = new Date();
            if (expireDate > now) {
                return true;
            } else {
                return false;
            }
        } else {
            // No authentication data set.
            return false;
        }
    }

    public getExternalIp(): Observable<{ ip: string }> {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<{ ip: string }>('https://api.ipify.org/?format=json', { headers });
    }

    public getAuth(): authModel {
        const auth = localStorage.getItem('auth');
        return auth ? JSON.parse(auth) : null;
    }

    private encodeBasicHeader(): string {
        return `Basic ${btoa(`${environment.application}:${environment.password}`)}`;
    }

    public logout() {
        // Remove the auth data from the local storage
        const offlineData = localStorage.getItem('OfflineData');

        // Clear the entire localStorage
        localStorage.clear();

        if (offlineData) {
            localStorage.setItem('OfflineData', offlineData);
        }

        this.router.navigate(['authentication/login'], { replaceUrl: true });
    }
}
