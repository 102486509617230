import { MenuItem } from '../models/menu.model';

export class HeaderMenu {
    public static pages: MenuItem[] = [
        {
            group: '',
            separator: true,
            items: [
                {
                    icon: 'assets/icons/heroicons/outline/cog.svg',
                    label: 'Dashboard',
                    route: '/dashboard/lopend',
                },
                {
                    icon: 'assets/icons/heroicons/outline/cog.svg',
                    label: 'Bedrijfsinformatie',
                    route: '/bedrijfsinformatie/bedrijfsgegevens',
                },
            ]
        }            
    ];
}
