export class StorageKeys {
    static readonly FeedbackTypes = 'FeedbackTypes';
    static readonly LastSync = 'LastSync';
    static readonly BodyTypes = 'BodyTypes';
    static readonly DriveTypes = 'DriveTypes';
    static readonly FuelTypes = 'FuelTypes';
    static readonly Makes = 'Makes';
    static readonly TransmissionTypes = 'TransmissionTypes';
    static readonly VehicleTypes = 'VehicleTypes';
    static readonly DealSummaryTypes = 'DealSummaryTypes';
}
