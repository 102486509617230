import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderMenuComponent } from './components/header/header-menu/header-menu.component';
import { HeaderMobileComponent } from './components/header/header-mobile/header-mobile.component';
import { HeaderMobileMenuComponent } from './components/header/header-mobile/header-mobile-menu/header-mobile-menu.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarMenuComponent } from './components/sidebar/sidebar-menu/sidebar-menu.component';
import { SidebarSubmenuComponent } from './components/sidebar/sidebar-submenu/sidebar-submenu.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CapitalizePipe } from '../pipes/capitalize.pipe';
import { RegistrationPipe } from '../pipes/registration.pipe';
import { routes } from './layout-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { ModalService } from '../services/modal.service';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { StatusPipe } from '../pipes/status.pipe';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    HeaderMenuComponent,
    HeaderMobileComponent,
    HeaderMobileMenuComponent,
    SidebarComponent,
    SidebarMenuComponent,
    SidebarSubmenuComponent,
    CapitalizePipe,
    RegistrationPipe,
    StatusPipe,
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule.forRoot(),
    ToastrModule.forRoot(),
    RouterModule.forRoot(routes),
    ReactiveFormsModule,
  ],
  exports: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    CapitalizePipe,
    RegistrationPipe,
    StatusPipe,
  ],
  providers: [
    ModalService
  ]
})
export class LayoutModule { }
