<div [ngClass]="
    menuService.showMobileMenu
      ? 'pointer-events-auto scale-100 animate-fade-in-up opacity-100 duration-200'
      : 'pointer-events-none scale-95 opacity-0 duration-100 ease-out'
  " class="absolute inset-x-0 top-0 z-999 origin-top-right transform p-2 transition md:hidden">
  <div
    class="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-night-700">
    <div class="pt-5 pb-6">
      <div class="flex items-center justify-between px-5">
        <div>
        </div>

        <div class="dropdown relative inline-block" *ngFor="let menu of menuService.headerMenu[0].items">
          <button
            [routerLink]="menu.route"
            routerLinkActive="bg-gray-200 text-gray-900"
            [ngClass]="menu.active ? 'bg-green-500 text-white' : 'text-gray-600 hover:bg-gray-100 hover:text-primary-500 dark:text-night-200 dark:hover:bg-night-600'"
            class="mr-2 inline-flex rounded-md px-3 py-2 text-lg font-medium">
            <span>{{ menu.label }}</span>
          </button>
        </div>

        <div class="-mr-2">
          <button (click)="toggleMobileMenu()" type="button"
            class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 hover:bg-gray-100 hover:text-gray-500 dark:text-night-300 dark:hover:bg-night-600 dark:hover:text-night-200">
            <span class="sr-only">Close menu</span>
            <!-- Heroicon name: outline/x -->
            <svg-icon src="assets/icons/heroicons/outline/x.svg"> </svg-icon>
          </button>
        </div>
      </div>
      <div
        class="scrollbar-thumb-rounded scrollbar-track-rounded max-h-[500px] overflow-y-auto px-5 scrollbar-thin scrollbar-track-gray-50 scrollbar-thumb-gray-100 dark:scrollbar-thumb-night-500 dark:scrollbar-track-night-600">
        <app-header-mobile-menu></app-header-mobile-menu>
      </div>
    </div>
  </div>
</div>