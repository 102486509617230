import { Routes } from '@angular/router';
import { AuthenticationComponent } from './authentication.component';
import { LoginComponent } from './components/login/login.component';
import { SelectPersonComponent } from './components/select-person/select-person.component';
import { SelectCompanyComponent } from './components/select-company/select-company.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { NotFoundComponent } from './not-found/not-found.component';

export const routes: Routes = [
  {
    path: 'authentication',
    component: AuthenticationComponent,
    children: [
      { path: 'login', component: LoginComponent, data: { returnUrl: window.location.pathname } },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'select-person', component: SelectPersonComponent },
      { path: 'select-company', component: SelectCompanyComponent },
      { path: '**', component: NotFoundComponent }, // Catch-all for undefined routes
    ],
  },
];
