<div class="flex h-16 items-center bg-white dark:bg-night-700 border">
  <div class="flex w-full items-center justify-between px-7 text-[13px] font-medium">
    <div class="text-gray-400 dark:text-night-300">
      <span class="mr-2">©{{ year }}</span>
      <a href="https://github.com/luciano-work/angular-tailwind" target="_blank"
        class="font-normal text-gray-800 hover:text-primary-500 dark:text-night-100 dark:hover:text-primary-500">
        Autotaxatie Partners
      </a>
    </div>
    <ul class="flex text-gray-500 dark:text-night-300">
      <li class="hover:text-primary-500">
        <a routerLink="dashboard/voorwaarden"
          class="menu-link px-2">Voorwaarden</a>
      </li>
      <li class="hover:text-primary-500">
        <a routerLink="dashboard/ondersteuning"
          class="menu-link px-2">Ondersteuning</a>
      </li>
    </ul>
  </div>
</div>