import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { JwtInterceptor } from './authentication/services/jwt.interceptor';
import { routes } from './app.routes';
import { NgxLoadingModule } from "ngx-loading";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxFlickingModule } from '@egjs/ngx-flicking';

@NgModule({
    declarations: [
        AppComponent,
    ],
    bootstrap: [
        AppComponent
    ],
    imports: [
        NgxFlickingModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        LayoutModule,
        NgxLoadingModule.forRoot({}),
        AuthenticationModule,
        RouterModule.forRoot(routes),
        BrowserAnimationsModule], providers: [
            { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
            { provide: LOCALE_ID, useValue: 'nl-NL' },
            provideHttpClient(withInterceptorsFromDi()),
        ]
})
export class AppModule { }
