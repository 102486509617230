import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, ComponentRef, Type, EmbeddedViewRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  public openModal(component: Type<any>, data: any): { componentRef: ComponentRef<any>, afterClosed$: Observable<any> } {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = componentFactory.create(this.injector);

    componentRef.instance.modalRef = componentRef;
    componentRef.instance.modalData = data;

    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    const closeSubject = new Subject<any>();

    // Attach a close function to the component that can pass data
    componentRef.instance.closeModal = (result?: any) => {
      this.closeModal(componentRef, result);
      closeSubject.next(result);  // Emit the close data
      closeSubject.complete();
    };

    return {
      componentRef,
      afterClosed$: closeSubject.asObservable()
    };
  }

  public closeModal(componentRef: ComponentRef<any>, result?: any) {
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }
}
