<div class="flex h-screen w-full overflow-hidden">
  <div class="flex grow flex-col content-start overflow-hidden bg-gray-50 dark:bg-night-800">
    <!-- Header -->
    <app-header class="shadow-md"></app-header>
    
    <!-- main content -->
    <div id="main-content"
      class="scrollbar-thumb-rounded scrollbar-track-rounded grow overflow-auto scrollbar-thin scrollbar-thumb-white scrollbar-track-gray-100 dark:scrollbar-thumb-night-700 dark:scrollbar-track-night-800">
      <router-outlet></router-outlet>
    </div>

    <!-- footer -->
    <app-footer></app-footer>
  </div>
</div>