<nav [ngClass]="menuService.showSideBar ? 'w-72 xl:w-100' : 'w-[70px]'"
  class="z-10 scrollbar-thumb-rounded scrollbar-track-rounded hidden h-full flex flex-col overflow-auto transition-all duration-300 scrollbar-thin scrollbar-track-gray-50 scrollbar-thumb-gray-100 dark:bg-night-700 dark:scrollbar-thumb-night-500 dark:scrollbar-track-night-600 lg:flex fixed">
  <!-- Sidebar Content -->
  <div class="flex flex-col h-full bg-gray-200">
    <!-- Top Section -->
    <div class="flex-grow px-4">
      <div *ngIf="menuService.pagesMenu[0].shownewRequest" class="py-4 text-center">
        <button
        (click)="startSpecification()"
        class="flex items-center justify-center w-full bg-gradient-to-b from-[#5bb9a7] to-[#63adc7] text-white font-medium text-md px-6 py-2 
            rounded-full shadow-md hover:opacity-90 focus:ring-2 focus:ring-[#5bb9a7] transition duration-150 ease-in-out">
        <span class="mr-2 text-lg font-bold">+</span>
        Nieuwe aanvraag
        </button>
      </div>
      <app-sidebar-menu (menuItemPressed)="handleMenuItemPressed($event)"></app-sidebar-menu>
    </div>

    <img class="custom-background" src="../../../../assets/images/background.svg">

    <!-- "Hulp nodig?" Section -->
    <div class="px-6 pb-48 z-10">
      <div class="p-4 bg-white rounded-lg shadow-lg">
        <h2 class="text-sm font-bold text-gray-700 mb-4">Hulp nodig?</h2>
        <div class="flex items-center mb-2">
          <div class="flex items-center justify-center w-4 h-4 bg-teal-100 text-teal-500 rounded-full">
            <img src="../../../../assets/icons/heroicons/outline/envelope.svg" alt="Email Icon" class="w-5 h-5">
          </div>
          <p class="ml-4 text-sm text-gray-700">info&#64;autotaxatiepartners.nl</p>
        </div>
        <div class="flex items-center">
          <div class="flex items-center justify-center w-4 h-4 bg-teal-100 text-teal-500 rounded-full">
            <img src="../../../../assets/icons/heroicons/outline/phone.svg" alt="Phone Icon" class="w-5 h-5">
          </div>
          <p class="ml-4 text-sm text-gray-700">088-8833222</p>
        </div>
      </div>
    </div>
  </div>
</nav>