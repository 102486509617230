import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalize',
    standalone: false
})
export class CapitalizePipe implements PipeTransform {
  transform(value: any, isDescription: boolean = false, words: boolean = false): string {
    // Check if value is null or undefined
    if (value == null) {
      return 'Onbekend';
    }
    
    if (isDescription) {
      if (value.descriptionNl == null) {
        return 'Onbekend';
      } else {
        // Capitalize the first letter of each word if words is true
        if (words) {
          return value.descriptionNl.replace(/\b\w/g, (char: string) => char.toUpperCase());
        } else {
          // Capitalize the first letter of the entire string
          return value.descriptionNl.charAt(0).toUpperCase() + value.descriptionNl.slice(1);
        }
      }
    } else {
      // Capitalize the first letter of each word if words is true
      if (words) {
        return value.replace(/\b\w/g, (char: string) => char.toUpperCase());
      } else {
        // Capitalize the first letter of the entire string
        return value.charAt(0).toUpperCase() + value.slice(1);
      }
    }
  }
}
