import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './authenticationService.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(): boolean {
        if (this.authenticationService.checkLoginState()) {
            return true;
        }

        const currentUrl = window.location.pathname;

        if (!currentUrl.startsWith('/authentication/login')) {
            // Redirect with the base64-encoded current URL as a query parameter
            const encodedUrl = btoa(currentUrl);
            this.router.navigate(['authentication/login'], { queryParams: { returnUrl: encodedUrl } });
        } else {
            // Redirect to login without preserving the current URL
            this.router.navigate(['authentication/login']);
        }

        return false;
    }
}
