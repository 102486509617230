<div class="flex h-screen w-screen" >
    <!-- Container with full-width background image -->
    <div class="w-full h-full bg-cover flex background-image">
        <!-- Centered container for router-outlet -->
        <div class="flex flex-1 items-center justify-center animate__animated animate__fadeIn">
            <div class="w-full max-w-md lg:max-w-lg xl:max-w-xl px-4 sm:px-6 lg:px-8 mx-auto">
                <!-- Form container with largest border radius and custom shadow -->
                <div class="border-2 border-primary-100 rounded-3xl shadow-custom p-8 pt-8 bg-white">
                    <!-- logo -->
                    <div class="flex items-center justify-left mb-4">
                        <img src="../../assets/images/logo-text.png" alt="angular tailwind logo" class="h-20" />
                    </div>
                    <!-- Route -->
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <!-- Placeholder for the right half of the screen on larger screens -->
        <div class="flex-1 hidden lg:block"></div>
    </div>
    <!-- Other elements -->
</div>
