import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { ApiService } from './api.service';
import { StorageKeys } from '../models/storageKeys.model';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class LocalDataService {
    private syncing: boolean = false;  // Flag to prevent multiple syncs

    constructor(private apiService: ApiService, private toastr: ToastrService) { }

    /**
     * Fetches and saves all necessary data to local storage under a single key
     */
    public syncData(): Observable<void> {
        if (this.syncing) {
            return of();  // Avoid duplicate syncs
        }

        this.syncing = true;

        const requests = [
            { key: StorageKeys.FeedbackTypes, request: () => this.apiService.getFeedbackTypes(999, 1) },
            { key: StorageKeys.BodyTypes, request: () => this.apiService.getBodyTypes(999, 1) },
            { key: StorageKeys.DriveTypes, request: () => this.apiService.getDriveTypes(999, 1) },
            { key: StorageKeys.FuelTypes, request: () => this.apiService.getFuelTypes(999, 1) },
            { key: StorageKeys.Makes, request: () => this.apiService.getMakes(999, 1) },
            { key: StorageKeys.TransmissionTypes, request: () => this.apiService.getTransmissionTypes(999, 1) },
            { key: StorageKeys.VehicleTypes, request: () => this.apiService.getVehicleTypes(999, 1) },
            { key: StorageKeys.DealSummaryTypes, request: () => this.apiService.getSummaryTypes(999, 1) }
        ];

        // Map each request to its observable and gather results in an array using `forkJoin`
        const requestObservables = requests.map(({ key, request }) =>
            request().pipe(
                map((response) => ({ key, data: response.data })),
                catchError((error) => {
                    this.toastr.error(`Failed to fetch offline data for ${key}.`);
                    return of({ key, data: [] });  // Return an empty array if the request fails
                })
            )
        );

        // `forkJoin` waits for all observables to complete before emitting
        return forkJoin(requestObservables).pipe(
            tap((results) => {
                const syncData: any = {};

                // Populate `syncData` with results from all requests
                results.forEach(({ key, data }) => {
                    syncData[key] = data;
                });

                // Add the last sync time
                syncData[StorageKeys.LastSync] = new Date().toISOString();

                // Store the consolidated data
                localStorage.setItem('offlineData', JSON.stringify(syncData));

                // Reset the syncing flag
                this.syncing = false;
            }),
            map(() => { })  // Return an empty observable once complete
        );
    }

    /**
     * Verifies data integrity, ensuring all necessary fields are present.
     */
    private verifyAndFixDataIntegrity(data: any): void {
        const requiredKeys = [
            StorageKeys.FeedbackTypes,
            StorageKeys.BodyTypes,
            StorageKeys.DriveTypes,
            StorageKeys.FuelTypes,
            StorageKeys.Makes,
            StorageKeys.TransmissionTypes,
            StorageKeys.VehicleTypes,
            StorageKeys.DealSummaryTypes
        ];

        requiredKeys.forEach((key) => {
            if (!data[key]) {
                this.toastr.warning(`Offline data veld ${key}. ontbreekt, proberen om opnieuw op te halen...`);
                data[key] = [];  // Add a default empty array if data is missing
            }
        });
    }

    /**
     * Retrieves the last sync time from offline data in local storage
     */
    public getLastSync(): string | null {
        const offlineData = this.getOfflineData();
        return offlineData ? offlineData[StorageKeys.LastSync] : null;
    }

    /**
     * Retrieves a specific piece of data from local storage.
     * If data is not present, triggers a sync first.
     * @param key - The key under which the data is stored in offlineData
     */
    public getData(key: string): Observable<any> {
        const offlineData = this.getOfflineData();

        if (offlineData && offlineData[key]) {
            // If data is available, return it as an observable
            return of(offlineData[key]);
        } else {
            // If no data, trigger a sync and then return the requested data
            return this.syncData().pipe(
                map(() => {
                    const refreshedData = this.getOfflineData();
                    return refreshedData ? refreshedData[key] : null;
                })
            );
        }
    }

    public getAllData(): Observable<any> {
        const offlineData = this.getOfflineData();

        if (offlineData) {
            this.verifyAndFixDataIntegrity(offlineData);
            return of(offlineData);
        } else {
            return this.syncData().pipe(
                map(() => {
                    const refreshedData = this.getOfflineData();
                    this.verifyAndFixDataIntegrity(refreshedData);
                    return refreshedData;
                })
            );
        }
    }

    /**
     * Helper method to parse and retrieve offline data from local storage
     */
    private getOfflineData(): any | null {
        const data = localStorage.getItem('offlineData');
        return data ? JSON.parse(data) : null;
    }
}
