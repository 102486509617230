<div class="min-h-screen flex flex-col items-center justify-center bg-gray-100 text-gray-800">
    <h1 class="text-4xl font-bold mb-4 text-red-500">404 - Pagina Niet Gevonden</h1>
    <p class="text-lg mb-6 text-gray-600">Sorry, de pagina die je zoekt bestaat niet.</p>
    <a
      routerLink="/"
      class="px-6 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow hover:bg-blue-600 transition duration-300"
    >
      Ga naar de Homepage
    </a>
  </div>
  