import { Routes } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { AuthGuard } from '../authentication/services/auth.guard';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard] // Protect the dashboard route
      },
      {
        path: 'bedrijfsinformatie',
        loadChildren: () => import('../bedrijfsinformatie/bedrijfsinformatie.module').then(m => m.BedrijfsinformatieModule),
        canActivate: [AuthGuard] // Protect this route as well
      },
      { path: '**', redirectTo: 'error/404' }, // Handle invalid routes
    ]
  },
];
