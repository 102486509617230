import { Component } from '@angular/core';;
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authenticationService.service';
import { LocalDataService } from '../../../services/localData.service';

@Component({
    selector: 'app-select-company',
    templateUrl: './select-company.component.html',
    styleUrl: './select-company.component.scss',
    standalone: false
})
export class SelectCompanyComponent {
  public loading = false;
  public offlineDataLoading = false;
  public userInformation: any;
  private returnUrl: string | null = null;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private localDataService: LocalDataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // Retrieve the returnUrl only if it exists
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
    this.userInformation = JSON.parse(localStorage.getItem('authUserInformation') as string);
  }

  public selectCompany(company: any) {
    this.loading = true;
    const deviceToken = this.authenticationService.generateDeviceToken(255);

    this.authenticationService.updateUserInformation(company.id, this.userInformation.id).subscribe({
      next: (data) => {
        this.authenticationService.requestApiKey(deviceToken).subscribe({
          next: (apiKey) => {
            localStorage.setItem('authUserInformation', JSON.stringify(data));
            this.offlineDataLoading = true;
            this.localDataService.syncData().subscribe({
              next: () => {
                this.loading = false;
                if (localStorage.getItem('tempPassword')) {
                  this.router.navigateByUrl('/authentication/password-reset');
                } else {
                  // Navigate to the returnUrl if set, otherwise navigate to default route
                  if (this.returnUrl) {
                    this.router.navigateByUrl(atob(this.returnUrl));
                  } else {
                    this.router.navigateByUrl('/dashboard/lopend');
                  }
                }
              },
              error: (syncError) => {
                console.error('Error during sync data', syncError);
                this.loading = false;
              }
            });
          },
          error: (apiKeyError) => {
            console.error('Error requesting API key', apiKeyError);
            this.authenticationService.logout();
            this.loading = false;
          }
        });
      },
      error: (updateError) => {
        console.error('Error updating user information', updateError);
        this.authenticationService.logout();
        this.loading = false;
      }
    });
  }
}
