import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authenticationService.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrl: './forgot-password.component.scss',
    standalone: false
})
export class ForgotPasswordComponent {
  public form!: FormGroup;
  public submitted = false;
  public loading = false;
  public successMessage = false;
  public errorMessage = false;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _router: Router,
    private authenticaionService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      username: ['', [Validators.required]],
      login_type: ['person', [Validators.required]],
    });
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    this.authenticaionService.forgotPassword(this.f['username'].value, this.f['login_type'].value).subscribe(data => {
      if (data.error) {
        this.errorMessage = true;
        this.successMessage = false;
        this.loading = false;
      } else {
        this.errorMessage = false;
        this.successMessage = true;
        this.loading = false
      }
    });
  }
}
