import { Routes } from '@angular/router';
import { AuthGuard } from './authentication/services/auth.guard';
import { NotFoundComponent } from './authentication/not-found/not-found.component';

export const routes: Routes = [
    { path: '', redirectTo: 'authentication/login', pathMatch: 'full' }, 
    { 
        path: 'dashboard', 
        loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule), 
        canActivate: [AuthGuard] 
    },
    { 
        path: 'authentication', 
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) 
    },
    { path: '**', component: NotFoundComponent }, // Catch-all for undefined routes
];
