export const environment = {
  production: false,
  apiUrl: 'https://api-new-acc.autotaxatiepartners.nl',
  application: 'atp-dashboard',
  password: '53FdUOpyUh2zVEQhcEPf',
  // First item will be the default, one entry will hide the field.
  level: ['person', 'company'],

  inruilModuleDomain: 'https://acc-inkoop.autotaxatiepartners.nl',
  inruilModuleAppId: '108',
};