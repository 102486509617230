<div class="my-8 space-y-10">
    <div class="text-left">
        <h2 class="mb-1 text-2xl font-semibold text-gray-700 dark:text-white">Selecteer een bedrijf</h2>
    </div>

    <div class="space-y-3 text-left">
        <p class="text-sm font-normal text-gray-500 dark:text-gray-400">Inloggen gelukt! Selecteer een bedrijf uit onderstaande lijst om verder te gaan.</p>
        
        <!-- Loader -->
        <div *ngIf="loading" class="flex justify-center items-center">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 4.418 3.582 8 8 8v-4c-2.489 0-4.753-.949-6.464-2.478z"></path>
            </svg>
            <span>Bedrijf aan het selecteren...</span>
        </div>

        <!-- Company List -->
        <ul class="my-4" *ngIf="!loading">
            <li *ngFor="let company of userInformation.Companies" (click)="selectCompany(company)">
                <a class="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white cursor-pointer">
                    <span class="flex-1 ms-3 whitespace-nowrap">{{company.name}}</span>
                </a>
            </li>
        </ul>
        
        <p *ngIf="offlineDataLoading">Offline data aan het ophalen...</p>
    </div>
</div>
