import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuService } from '../../services/menu/menu.service';
import { MenuItem, SubMenuItem } from '../../../models/menu.model';
import { AuthenticationService } from '../../../authentication/services/authenticationService.service';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    standalone: false
})
export class SidebarComponent implements OnInit {
  @Input() menuItems: MenuItem[] = [];
  @Output() menuItemPressed: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();

  constructor(
    public menuService: MenuService,
    private authService: AuthenticationService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    if (this.menuItems.length > 0) {
      this.menuService.initializePagesMenu(this.menuItems);
    }
  }

  public toggleSidebar() {
    this.menuService.toggleSidebar();
  }

  public startSpecification() {
    this.toastrService.info('Bezig met het starten van een nieuwe aanvraag', 'Informatie');
    // Obtain authentication details
    const auth = this.authService.getAuth();

    const deviceToken = this.authService.generateDeviceToken(64);

    // Request api-key
    this.authService.requestApiKey(deviceToken).subscribe((response) => {
      if (response.error == false) {
        // Encode authentication details into URL
        const encodedCredentials = btoa(`${auth.username}:${deviceToken}:${response.data.apiKey}`);
        const apiUrl = `${environment.inruilModuleDomain}/${environment.inruilModuleAppId}/api-key/${encodedCredentials}`;

        // Open URL in a new tab
        window.open(apiUrl, '_blank');
      }
    });
  }

  // Method to handle the emitted menu item
  public handleMenuItemPressed(item: any) {
    // Emit the menu item pressed
    this.menuItemPressed.emit(item);
  }

  public updateMenuItem<K extends keyof SubMenuItem>(
    groupIndex: number,
    itemIndex: number,
    key: K,
    value: SubMenuItem[K]
  ): void {
    if (
      this.menuItems[groupIndex] &&
      this.menuItems[groupIndex].items[itemIndex]
    ) {
      // Update the specific property
      this.menuItems[groupIndex].items[itemIndex][key] = value;
  
      // Trigger Angular's change detection
      this.menuItems = [...this.menuItems];
    } else {
      console.warn('Menu item not found. Please check the indices.');
    }
  }  
}
