import { Component, Input, OnInit } from '@angular/core';
import { SubMenuItem } from '../../../../models/menu.model';
import { MenuService } from '../../../services/menu/menu.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sidebar-submenu',
    templateUrl: './sidebar-submenu.component.html',
    styleUrls: ['./sidebar-submenu.component.scss'],
    standalone: false
})
export class SidebarSubmenuComponent implements OnInit {
  @Input() public submenu = <SubMenuItem>{};

  constructor(public menuService: MenuService, private router: Router) {}

  ngOnInit(): void {
    this.expandMenu(this.submenu);
    this.markActiveItems(this.submenu);
  }
  
  private expandMenu(menu: any) {
    menu.expanded = true;
    if (menu.children) {
      menu.children.forEach((child: any) => this.expandMenu(child));
    }
  }

  private collapse(items: Array<any>) {
    items.forEach((item) => {
      item.expanded = false;
      if (item.children) this.collapse(item.children);
    });
  }

  private markActiveItems(menu: any) {
    if (menu.route === this.router.url) {
      menu.active = true;
    } else {
      menu.active = false;
    }

    if (menu.children) {
      menu.children.forEach((child: any) => this.markActiveItems(child));
    }
  }
}
