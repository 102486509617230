import { NgClass, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RouterLink, Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { AuthenticationService } from '../../services/authenticationService.service';
import { environment } from '../../../../environments/environment';
import { LocalDataService } from '../../../services/localData.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    standalone: false
})
export class LoginComponent {
  public form!: FormGroup;
  public loading = false;
  public offlineDataLoading = false;
  public submitted = false;
  public passwordTextType!: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private localDataService: LocalDataService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      login_type: [null, null],
      remember_me: [true, null],
    });

    this.form.controls['login_type'].setValue(environment.level[0]);
  }

  get f() {
    return this.form.controls;
  }

  public togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  public onSubmit() {
    this.submitted = true;
    const { username, password, login_type, remember_me } = this.form.value;

    // Stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.form.controls['username'].disable();
    this.form.controls['password'].disable();
    this.form.controls['remember_me'].disable();

    const deviceToken = this.authenticationService.generateDeviceToken(64);
    // Auth user with username and password.
    this.authenticationService.login(username, password, remember_me, deviceToken, login_type).subscribe(data => {
      // Check if we need to get the API key
      if (data.one_time_password === true) {
        // User used one time password. Navigate to password reset page.
        localStorage.setItem('tempPassword', 'true');
        localStorage.setItem('password', this.form.controls['password'].value);
        this.router.navigateByUrl('/authentication/reset-password');
      } else {
        // Get user information to show select user page (or not)
        this.authenticationService.getAuthUserInformation().subscribe(data => {
          // Retrieve the returnUrl from query params
          const returnUrl = this.route.snapshot.queryParams['returnUrl'];
          if (data.Company.id !== undefined && data.Employee.id !== undefined) {
            localStorage.setItem('authUserInformation', JSON.stringify(data));
            this.offlineDataLoading = true;
            this.localDataService.syncData().subscribe(offlineData => {
              // Check if remember me is checked, save api key.
              if (this.form.controls['remember_me'].value) {
                this.authenticationService.requestApiKey(deviceToken).subscribe(data => {
                  // Logged in with access_token and api_key
                  this.loading = false;
                  this.navigateToLoggedInPage(returnUrl);
                });
              } else {
                // Logged in with access_token
                this.loading = false;
                this.navigateToLoggedInPage(returnUrl);
              }
            });
          } else if (data.Company.id === undefined) {
            // Show list with employees
            localStorage.setItem('authUserInformation', JSON.stringify(data));
            const navigationExtras: NavigationExtras = returnUrl ? { queryParams: { returnUrl } } : {};
            this.router.navigate(['/authentication/select-company'], navigationExtras);
          } else if (data.Employee.id === undefined) {
            // Show list with companies
            localStorage.setItem('authUserInformation', JSON.stringify(data));
            const navigationExtras: NavigationExtras = returnUrl ? { queryParams: { returnUrl } } : {};
            this.router.navigate(['/authentication/select-person'], navigationExtras);
          }
        });
      }
    },
      error => {
        this.form.reset();
        this.form.controls['login_type'].setValue(environment.level[0], { onlySelf: true });
        this.loading = false;
        this.form.controls['username'].enable();
        this.form.controls['password'].enable();
        this.form.controls['remember_me'].enable();
      });
  }

  private navigateToLoggedInPage(returnUrl: string | null) {
    if (returnUrl !== null) {
      this.router.navigateByUrl(atob(returnUrl));
    } else {
      this.router.navigate(['/']);
    }
  }
}
