import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authenticationService.service';
import { ToastrService } from 'ngx-toastr';
import { LocalDataService } from '../../../services/localData.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: false
})
export class ResetPasswordComponent {
  public form!: FormGroup;
  public submitted = false;
  public loading = false;
  public successMessage = false;
  public errorMessage = false;
  public offlineDataLoading = false;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private authenticaionService: AuthenticationService,
    private toastr: ToastrService,
    private localDataService: LocalDataService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      new_password: ['', [Validators.required, Validators.minLength(8), this.passwordStrengthValidator]],
      confirm_password: ['', [Validators.required]]
    }, {
      validator: this.matchPasswords('new_password', 'confirm_password')
    });
  }

  // Custom getter for easy access to form controls
  get f() {
    return this.form.controls;
  }

  // Password strength validator: at least one uppercase letter, one number, and one special character
  passwordStrengthValidator(control: any): { [key: string]: boolean } | null {
    const value = control.value || '';
    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

    if (!hasUpperCase || !hasNumber || !hasSpecialChar) {
      return { weakPassword: true };
    }
    return null;
  }

  // Validator to ensure new_password and confirm_password match
  matchPasswords(newPasswordKey: string, confirmPasswordKey: string) {
    return (formGroup: FormGroup) => {
      const newPassword = formGroup.controls[newPasswordKey];
      const confirmPassword = formGroup.controls[confirmPasswordKey];

      if (newPassword.value !== confirmPassword.value) {
        confirmPassword.setErrors({ passwordMismatch: true });
      } else {
        confirmPassword.setErrors(null);
      }
    };
  }

  onSubmit() {
    this.submitted = true;

    // Stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    // Get password out of local storage
    const password = localStorage.getItem('password');

    if (!password) {
      this.toastr.error('Password not found. Please login again.');
      return;
    }

    this.authenticaionService.updateAccount(this.f['new_password'].value, password).subscribe({
      next: () => {
        this.errorMessage = false;
        // Remove password from local storage
        localStorage.removeItem('password');
        localStorage.removeItem('tempPassword');
        this.toastr.success('Uw wachtwoord is succesvol gewijzigd.');

        const deviceToken = this.authenticaionService.generateDeviceToken(64);
        // Get user information to show select user page (or not)
        this.authenticaionService.getAuthUserInformation().subscribe(data => {
          if (data.Company.id !== undefined && data.Employee.id !== undefined) {
            localStorage.setItem('authUserInformation', JSON.stringify(data));
            this.offlineDataLoading = true;
            this.localDataService.syncData().subscribe(offlineData => {
              // Check if remember me is checked, save api key.
              if (this.form.controls['remember_me'].value) {
                this.authenticaionService.requestApiKey(deviceToken).subscribe(data => {
                  // Logged in with access_token and api_key
                  this.loading = false;
                  this.navigateToLoggedInPage();
                });
              } else {
                // Logged in with access_token
                this.loading = false;
                this.navigateToLoggedInPage();
              }
            });
          } else if (data.Company.id === undefined) {
            // Show list with employees
            localStorage.setItem('authUserInformation', JSON.stringify(data));
            this.router.navigateByUrl('/authentication/select-company')
          } else if (data.Employee.id === undefined) {
            // Show list with companies
            localStorage.setItem('authUserInformation', JSON.stringify(data));
            this.router.navigateByUrl('/authentication/select-person')
          }
        });
      },
      error: () => {
        this.errorMessage = true;
        this.successMessage = false;
        this.loading = false;
      }
    });
  }

  private navigateToLoggedInPage() {
    const returnUrl = this.route.snapshot.paramMap.get('return_url');
    if (returnUrl !== null) {
      this.router.navigateByUrl(atob(returnUrl));
    } else {
      this.router.navigate(['/']);
    }
  }
}
